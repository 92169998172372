<template>
    <div class="contact-us position-fixed t-0 b-0 container-fluid" @click="hideMenu">
        <!-- <img src="@/assets/img/left-side-mobile.svg" class="side left-side" alt="">
        <img src="@/assets/img/top-side.svg" class="side top-side" alt=""> -->
        <img src="@/assets/img/mobile-char-res.svg" class="side mobile-char" alt="">
        <div class="row z-1  h-100">
            <div class="col-12 px-0">
                <div class="title position-relative justify-content-start mt-3">
                    <img src="@/assets/img/title-mobile.svg" class="w-100" alt="">
                </div>
            </div>
            <div class="col-12 ms-auto px-0">
                <div class="d-flex flex-wrap flex-50 justify-content-evenly justify-content-md-center">
                    <div class="entity">
                        <div class="position-relative d-flex flex-column align-items-center z-2" id="except">
                            <img src="@/assets/img/mobile.svg" alt="mobile-icon" @click="showMenu">
                            <div class="position-absolute t-100" v-show="isShown">
                                <PhoneDropDown />
                            </div>


                        </div>
                        <p class="name regular f-18 mt-1">
                            <span class="f-14">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.489" height="6.294" viewBox="0 0 10.489 6.294">
                                    <path id="Polygon_3" data-name="Polygon 3" d="M5.245,0l5.245,6.294H0Z" transform="translate(10.489 6.294) rotate(180)" fill="#fff"/>
                                </svg>
                            </span>
                            By Phone
                        </p>
                    </div>
                    <a class="entity" href="http://wa.me/+971509559785" target="_blank">
                        <img src="@/assets/img/whatsapp.svg" alt="whatsapp-icon">
                        <p class="name regular f-18 mt-1">
                            On WhatsApp
                        </p>
                    </a>
                    <a class="entity" href="https://jumlatech.com/" target="_blank">
                        <img src="@/assets/img/website.svg" alt="website-icon">
                        <p class="name regular f-18 mt-1">
                            On The Web
                        </p>
                    </a>
                    <a class="entity" href="https://t.me/JumlaTech_SY" target="_blank">
                        <img src="@/assets/img/telegram.svg" alt="telegram-icon">
                        <p class="name regular f-18 mt-1">
                            On Telegram
                        </p>
                    </a>
                    <a class="entity" href="https://www.facebook.com/JumlaTech.Sy" target="_blank">
                        <img src="@/assets/img/facebook.svg" alt="facebook-icon">
                        <p class="name regular f-18 mt-1">
                            On Facebook
                        </p>
                    </a>
                    <a class="entity" href="https://www.instagram.com/JumlaTech.Sy" target="_blank">
                        <img src="@/assets/img/insta.svg" alt="instagram-icon">
                        <p class="name regular f-18 mt-1">
                            On Instagram
                        </p>
                    </a>


                </div>
            </div>
            <div class="col-12 download text-end d-flex align-items-end ustify-content-end;">
                <a target="_blank" class="d-flex mt-auto me-auto" href="https://web.jt.sy/downloads/latest.apk">
                    <img src="@/assets/img/download.svg" alt="">
                </a>
            </div>
        </div>

    </div>
</template>
  
<script>
import PhoneDropDown from '@/components/PhoneDropDown.vue';

// @ is an alias to /src

export default {
    data() {
        return {
            shown: false
        }
    }
    ,
    mounted() {
        if (window.innerWidth >= 1199.98)
            this.$router.push({ name: 'contact-us' });
        else
            this.$router.push({ name: 'contact-us-mobile' });
    },
    methods: {
        showMenu(ev){
        if(!this.shown)
          ev.stopPropagation()
        this.shown = !this.shown

      },
      hideMenu(){
        this.shown = false;
      }
    },
    components: { PhoneDropDown },
    computed: {
        isShown() {
            return this.shown
        }
    }
}
</script>
<style scoped>
.t-100 {
    top: 95%;
}

.contact-us {
    background-color: var(--primary-color);
    height: 100%;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.t-0 {
    top: 0
}

.b-0 {
    bottom: 0;
}

.mobile-char {
    right: 0;
    bottom: 0;
}

.left-side {
    right: 0;
    bottom: 0;
}

.right-side {
    bottom: 0;
    left: 0;
    z-index: 1;

}

.top-side {
    top: 0;
    left: 0;
}

.side {
    position: absolute;
    max-width: 100%;
}

h1,
.name,
p {
    color: white;
}

.title {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
}

.right-deco {
    position: absolute;
    right: 4%;
    top: -22%;
}

.left-deco {
    position: absolute;
    left: 8%;
    top: -9%;
}

.name {
    margin-bottom: 0 !important;
}

.entity img {
    height: 9vh;
}

.d-flex .entity {
    margin-bottom: 3vh;
}

.download img {
    margin-right: 3%;
}

.download {
    overflow-y: hidden;

}

.z-2 {
    z-index: 1;
}

a {
    text-decoration: none
}

.z-1 {
    z-index: 1;
}

.flex-50 .entity {
    width: 35%
}

.w-65 {
    width: 55%;
}

.row {
    /* align-content: space-between; */
}

.download img {
    height: 9.5vh;

}

.mobile-char {
    height: 21vh;
}

@media (min-width: 600px) and (max-width: 1199.98px) {

    .left-deco,
    .right-deco {
        display: none;
    }

    .flex-50 .entity {
        width: 30%
    }
}

@media (max-height: 600px) and (max-width: 380px) {
    .entity img {
        height: 8vh;
    }

    .flex-50.justify-content-evenly {
        justify-content: center !important
    }

}
</style>
  