<template>
  <div class="contact-us position-relative container-fluid"  @click="hideMenu">
    <img src="@/assets/img/mobile-char.svg" class="side mobile-char" alt="">
    <div class="row z-1 h-100 align-content-between">
      <div class="col-12 px-0">
        <div class="title position-relative justify-content-center">
          <img src="@/assets/img/title.svg" class="w-100" alt="">
        </div>
      </div>
      <div class="col-6 me-auto px-0">
        <div class="d-flex mr-8 justify-content-end">
          <div class="entity pointer"  @click="showMenu">
            <div class="position-relative d-flex flex-column align-items-center z-2" id="except">
              <img src="@/assets/img/mobile.svg" alt="mobile-icon">
              <div class="position-absolute t-100" v-show="isShown">
                <PhoneDropDown />
              </div>
              

            </div>
            <p class="name regular f-18 mt-1">
             <span class="f-14"><svg xmlns="http://www.w3.org/2000/svg" width="10.489" height="6.294" viewBox="0 0 10.489 6.294">
  <path id="Polygon_3" data-name="Polygon 3" d="M5.245,0l5.245,6.294H0Z" transform="translate(10.489 6.294) rotate(180)" fill="#fff"/>
</svg></span> 
              By Phone
            </p>
          </div>
          <a class="entity" href="http://wa.me/+971509559785" target="_blank">
            <img src="@/assets/img/whatsapp.svg" alt="whatsapp-icon">
            <p class="name regular f-18 mt-1">
              On WhatsApp
            </p>
          </a>
          <a class="entity" href="https://t.me/JumlaTech_SY" target="_blank">
            <img src="@/assets/img/telegram.svg" alt="telegram-icon">
            <p class="name regular f-18 mt-1">
              On Telegram
            </p>
          </a>
        </div>
        <div class="d-flex mr-8 justify-content-end">
          <a class="entity" href="https://jumlatech.com/" target="_blank">
            <img src="@/assets/img/website.svg" alt="website-icon">
            <p class="name regular f-18 mt-1">
              On The Web
            </p>
          </a>

          <a class="entity" href="https://www.facebook.com/JumlaTech.Sy" target="_blank">
            <img src="@/assets/img/facebook.svg" alt="facebook-icon">
            <p class="name regular f-18 mt-1">
              On Facebook
            </p>
          </a>
          <a class="entity" href="https://www.instagram.com/JumlaTech.Sy" target="_blank">
            <img src="@/assets/img/insta.svg" alt="instagram-icon">
            <p class="name regular f-18 mt-1">
              On Instagram
            </p>
          </a>
        </div>
      </div>
      <div class="col-12 text-end download">
        <a target="_blank" class="mr-8 d-block w-fit-content" href="https://web.jt.sy/downloads/latest.apk">
    
            <img src="@/assets/img/stand.svg" class="w-fit-content stand road-ad mr-auto " alt="">
        </a>
   
      </div>
    </div>

  </div>
</template>

<script>
import PhoneDropDown from '@/components/PhoneDropDown.vue';

// @ is an alias to /src

export default {
  data(){
    return {
      shown:false,
      was:false
    }
  }
    ,mounted() {
        if (window.innerWidth < 1199.98)
            this.$router.push({ name: 'contact-us-mobile' });
        else
            this.$router.push({ name: 'contact-us' });

    },
    methods:{
      showMenu(ev){
        if(!this.shown)
          ev.stopPropagation()
        this.shown = !this.shown

      },
      hideMenu(){
        this.shown = false;
      }
    },
    components: { PhoneDropDown },
    computed:{
      isShown(){
        return this.shown
      }
    }
}
</script>
<style scoped>
.f-14{
  font-size: 12px;
}
.t-100{
  top: 95%;
}
.contact-us {
  background-color: var(--primary-color);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
}


.mobile-char {
  right: 0;
  bottom: 0;
  height: 60vh;
}
.w-fit-content{
  width:fit-content
}
.left-side {
  right: 0;
  bottom: 0;
}

.right-side {
  bottom: 0;
  left: 0;
  z-index: 1;

}

.top-side {
  top: 0;
  left: 0;
}

.side {
  position: fixed;
  max-width: 100%;

}

h1,
.name,
p {
  color: white;
}

.title {
  display: flex;
  align-items: center;
  text-wrap: nowrap;
}

.right-deco {
  position: absolute;
  right: -14%;
  top: -17%;
}

.left-deco {
  position: absolute;
  left: -14%;
  top: -17%;

}

.name {
  margin-bottom: 0 !important;
}

.entity img {
  height: 12.29vh;
}

.mr-8 {
  margin-left: 8.8vw
}

.d-flex .entity {
  margin-left: 3.3vw;
  margin-right: 3.3vw;
  margin-bottom: 2vh;
}

.d-flex .entity:first-child,
.d-flex .entity:last-child {
  margin-left: 0;
  margin-right: 0;
}

.road-ad {
  width: fit-content;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.download-app {
  margin-right: 10px;
}

.road-ad {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stand {
  margin-top: -10px;
  position: relative;
  z-index: 0;
}

.download {
  overflow-y: hidden
}

.z-2 {
  z-index: 1;
}

a {
  text-decoration: none
}

.z-1 {
  z-index: 1;
}

@media (max-width: 1400px) {
  /* .right-deco {
    right: 36vw;
    top: 30%;
  }

  .left-deco {
    left: 36vw;
    top: 30%;
  } */
}</style>
