<template>
  <div id="control-height"></div>
  <router-view />
</template>
<script>
export default {
  mounted() {
    this.redirectTo();
    this.$nextTick(() => {
      window.addEventListener("resize", this.redirectTo);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.redirectTo);
  },
  methods: {
    redirectTo() {
      // const root_theme = document.querySelector(':root');
      // const visibleHeight = window.innerHeight;
      // root_theme.style.setProperty('--actual-height', visibleHeight);
      if (window.innerWidth >= 1199.98)
        this.$router.push({ name: 'contact-us'})
      else
        this.$router.push({ name: 'contact-us-mobile' })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#control-height {
  height: 100vh;
  width: 0;
  position: absolute;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
