<template>
    <div class="position-relative text-center" id="PhonDropDown">
        <img src="@/assets/img/dropdown.svg" class="w-14vw mx-auto" alt="">

        <div class="d-flex position-absolute w-100 t-0 b-0 flex-column align-items-center justify-content-evenly">
            <p class="name regular f-18 mt-6 white mb-0 pointer" >
                <span class="f-14 rotate">
                    <svg class="rotate" xmlns="http://www.w3.org/2000/svg" width="10.489" height="6.294" viewBox="0 0 10.489 6.294">
                       <path id="Polygon_3" data-name="Polygon 3" d="M5.245,0l5.245,6.294H0Z" transform="translate(10.489 6.294) rotate(180)" fill="#fff"/>
                    </svg>
                </span>
                By Phone
            </p>
            <a class="first" href="tel:+971509559785" target="_blank">
                <p class="name medium f-13 mb-0 white">
                    Mostapha Albaghdadi
                </p>
                <p class="name light f-12 mb-0 white">
                    Co-Founder & CEO
                </p>
            </a>
            <a class="second" href="tel:+971561327966" target="_blank">
                <p class="name medium f-13 mb-0 white">
                    Nazir Salem
                </p>
                <p class="name light f-12 mb-0 white">
                    Co-Founder
                </p>
            </a>
            <a class="link" href="tel:+963950001358" target="_blank">
                <p class="name meduim f-13 mb-0 white">
                    Anas Alsaadi
                </p>
                <p class="name light f-12 mb-0 white">
                    Chief Operating Officer
                </p>
            </a>
        </div>
    </div>
</template>

<style scoped>
.rotate {
    transform: rotate(180deg);
}

.t-0 {
    top: 0
}

.b-0 {
    bottom: 0;
}

.f-12 {
    font-size: 12px
}

.f-13 {
    font-size: 13px
}

.w-14vw {
    width: 10.2vw;
}

.white {
    color: white;
}

.first {
    margin-bottom: 1%;
    margin-top: 16%;
}

.second {
    margin-bottom: 10%;
    margin-top: 8%;
}

a,
a:hover {
    color: white;

    text-decoration: none;
}

.mt-6 {

    margin-top: 6%;
}

@media (max-width: 768px) {
    .f-12 {
        font-size: 10px
    }

    .f-13 {
        font-size: 10px
    }

    .w-14vw {
        width: 33vw;
    }
}</style>