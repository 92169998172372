import { createRouter, createWebHistory } from 'vue-router'
import ContactUsMobile from '@/views/ContactUsMobile.vue'
import ContactUs from '@/views/ContactUs.vue'

const routes = [
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs
  },
  {
    path: '/contact-us-mobile',
    name: 'contact-us-mobile',
    component: ContactUsMobile
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
